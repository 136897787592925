/* eslint-disable prettier/prettier */
import axiosInstance from 'src/utils/axios';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { buildQueryString } from 'src/utils/function';

interface CourseState {
  courseData: {
    count: number;
    rows: any;
  };
  courseDataloading: boolean;
  courseWithFlashcardData: {
    count: number;
    rows: any;
  };
  courseWithFlashcardDataLoading: any;
  fetchCourseData: any;
  fetchCourseDataLoading: boolean;
  fetchCourseDataSuccess: boolean;
  fetchCourseDataError: boolean;
  createCourseSuccess: boolean;
  createCourseError: boolean;
  createCourseLoading: boolean;
  updateCourseSuccess: boolean;
  updateCourseError: boolean;
  // updateCourseErrorMeassage: any;
  // createCourseErrorMeassage: any;
  // deleteCourseErrorMeassage: any;
  updateCourseLoading: boolean;
  deleteCourseSuccess: boolean;
  deleteCourseError: boolean;
  deleteCourseLoading: boolean;
}

const initialState: CourseState = {
  courseData: {
    count: 0,
    rows: [],
  },
  courseDataloading: false,
  courseWithFlashcardData: {
    count: 0,
    rows: [],
  },
  courseWithFlashcardDataLoading: false,
  fetchCourseData: {},
  fetchCourseDataError: false,
  fetchCourseDataLoading: false,
  fetchCourseDataSuccess: false,
  createCourseError: false,
  createCourseSuccess: false,
  createCourseLoading: false,
  updateCourseError: false,
  // updateCourseErrorMeassage: '',
  // createCourseErrorMeassage: '',
  // deleteCourseErrorMeassage: '',
  updateCourseSuccess: false,
  updateCourseLoading: false,
  deleteCourseError: false,
  deleteCourseSuccess: false,
  deleteCourseLoading: false,
};

const courseSlice = createSlice({
  name: 'course',
  initialState,
  reducers: {
    startCourseloading(state) {
      state.courseDataloading = true;
    },
    getCourseData(state, action: PayloadAction<any>) {
      state.courseDataloading = false;
      state.courseData = action.payload;
    },
    stopCourseLoading(state) {
      state.courseDataloading = false;
    },
    startCourseWithFlashcardloading(state) {
      state.courseWithFlashcardDataLoading = true;
    },
    getCourseWithFlashcardData(state, action: PayloadAction<any>) {
      state.courseWithFlashcardDataLoading = false;
      state.courseWithFlashcardData = action.payload;
    },
    stopCourseWithFlashcardLoading(state) {
      state.courseWithFlashcardDataLoading = false;
    },
    startCourseByIdloading(state) {
      state.fetchCourseDataLoading = true;
    },
    updateFlashcardCount(state, action: PayloadAction<{ courseId: number; delta: number }>) {
      const { courseId, delta } = action.payload;
      const course = state?.courseWithFlashcardData?.rows?.find((row) => row?.id === courseId);
      if (course) {
        course.flashCardCount += delta;
      }
    },
    getCourseByIdData(state, action: PayloadAction<any>) {
      state.fetchCourseDataLoading = false;
      state.fetchCourseData = action.payload;
    },
    stopCourseByIdLoading(state) {
      state.fetchCourseDataLoading = false;
    },
    createCourseLoading(state) {
      state.createCourseLoading = true;
    },
    createCourseSuccess(state) {
      state.createCourseSuccess = true;
      state.createCourseLoading = false;
    },
    createCourseError(state) {
      state.createCourseError = true;
      state.createCourseLoading = false;
    },
    clearCreateCourseData(state) {
      state.createCourseSuccess = false;
      state.createCourseLoading = false;
      state.createCourseError = false;
    },
    updateCourseLoading(state) {
      state.updateCourseLoading = true;
    },
    updateCourseSuccess(state) {
      state.updateCourseSuccess = true;
      state.updateCourseLoading = false;
    },
    updateCourseError(state) {
      state.updateCourseError = true;
      state.updateCourseLoading = false;
    },
    // updateCourseErrorMeassage(state) {
    //   state.updateCourseErrorMeassage = true;
    //   state.updateCourseLoading = false;
    // },
    // deleteCourseErrorMeassage(state) {
    //   state.deleteCourseErrorMeassage = true;
    //   state.updateCourseLoading = false;
    // },
    // createCourseErrorMeassage(state) {
    //   state.createCourseErrorMeassage = true;
    //   state.updateCourseLoading = false;
    // },
    clearUpdateCourseData(state) {
      state.updateCourseLoading = false;
      state.updateCourseError = false;
      state.updateCourseSuccess = false;
    },
    deleteCourseLoading(state) {
      state.deleteCourseLoading = true;
    },
    deleteCourseSuccess(state) {
      state.deleteCourseSuccess = true;
      state.deleteCourseLoading = false;
    },
    deleteCourseError(state) {
      state.deleteCourseError = true;
      state.deleteCourseLoading = false;
    },
    clearDeleteCourseData(state) {
      state.deleteCourseError = false;
      state.deleteCourseLoading = false;
      state.deleteCourseSuccess = false;
    },
  },
});

export const {
  startCourseloading,
  getCourseData,
  stopCourseLoading,
  createCourseError,
  createCourseSuccess,
  createCourseLoading,
  updateCourseError,
  // updateCourseErrorMeassage,
  // createCourseErrorMeassage,
  // deleteCourseErrorMeassage,
  updateFlashcardCount,
  updateCourseSuccess,
  updateCourseLoading,
  deleteCourseError,
  deleteCourseSuccess,
  deleteCourseLoading,
  getCourseWithFlashcardData,
  startCourseWithFlashcardloading,
  stopCourseWithFlashcardLoading,
  getCourseByIdData,
  startCourseByIdloading,
  stopCourseByIdLoading,
  clearCreateCourseData,
  clearDeleteCourseData,
  clearUpdateCourseData,
} = courseSlice.actions;

export default courseSlice.reducer;

export const getAllCourses =
  (query: Params): any =>
  async (dispatch: any) => {
    try {
      dispatch(startCourseloading());
      const response = await axiosInstance.get(`/course/admin/course${buildQueryString(query)}`); // Replace with your API endpoint
      dispatch(getCourseData(response.data.data));
    } catch (error) {
      dispatch(stopCourseLoading(error.message));
    }
  };

export const createCourse =
  (data: any, enqueueSnackbar: any): any =>
  async (dispatch: any) => {
    try {
      dispatch(createCourseLoading());
      await axiosInstance.post(`/course`, data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }); // Replace with your API endpoint
      dispatch(createCourseSuccess());
      dispatch(getAllCourseWithFlashcard({ page: 1, pageSize: 10 }));
    } catch (error) {
      dispatch(createCourseError());
      enqueueSnackbar(error.message ?? 'Something went wrong', { variant: 'error' });
    }
  };

export const updateCourse =
  (data: any, id: number, enqueueSnackbar: any): any =>
  async (dispatch: any) => {
    try {
      dispatch(updateCourseLoading());
      await axiosInstance.patch(`/course/${id}`, data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }); // Replace with your API endpoint
      dispatch(updateCourseSuccess());
      dispatch(getAllCourseWithFlashcard({ page: 1, pageSize: 10 }));
    } catch (error) {
      dispatch(updateCourseError());
      enqueueSnackbar(error.message ?? 'Something went wrong', { variant: 'error' });
    }
  };

export const getAllCourseWithFlashcard =
  (query: Params): any =>
  async (dispatch: any) => {
    try {
      dispatch(startCourseWithFlashcardloading());
      const response = await axiosInstance.get(
        `/course/admin/courseflashcard${buildQueryString(query)}`
      ); // Replace with your API endpoint
      dispatch(getCourseWithFlashcardData(response.data.data));
    } catch (error) {
      dispatch(stopCourseWithFlashcardLoading(error.message));
    }
  };

export const getCourseById =
  (id: number): any =>
  async (dispatch: any) => {
    try {
      dispatch(startCourseByIdloading());
      const response = await axiosInstance.get(`/course/admin/${id}`); // Replace with your API endpoint
      dispatch(getCourseByIdData(response.data));
    } catch (error) {
      dispatch(stopCourseByIdLoading(error.message));
    }
  };

export const deleteCourse =
  (id: number): any =>
  async (dispatch: any) => {
    try {
      dispatch(deleteCourseLoading());
      await axiosInstance.patch(`/course/delete/${id}`); // Replace with your API endpoint
      dispatch(deleteCourseSuccess());
      dispatch(getAllCourseWithFlashcard({ page: 1, pageSize: 10 }));
    } catch (error) {
      dispatch(deleteCourseError(error?.message));
    }
  };
